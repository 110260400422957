<template>
  <Page current-link="login">
    <div class="form col-center">
      <TitleLg>Login</TitleLg>
      <CreamSection>
        <div style="height: 50px"></div>
        <div class="col-center page">
          <FormSection>
            <form name="login" @submit.prevent="login">
              <div class="input-container input">
                <input v-model="email" type="email" autocomplete="email"/>
                <label for="email">Email</label>
              </div>
              <div class="input-container input">
                <input
                    v-model="password"
                    type="password"
                    autocomplete="current-password"
                />
                <label for="password">Password</label>
              </div>
<!--              <ForgotPasswordLink/>-->
              <button class="button">Log in</button>
            </form>
            <RegisterLink/>
          </FormSection>
        </div>
        <div style="height: 50px"></div>
      </CreamSection>
    </div>
  </Page>
</template>

<script setup lang="ts">
const email = ref("");
const password = ref("");

const {$dwebg} = useNuxtApp();

// const router = useRouter();
async function login() {
  try {
    console.log("logging in");

    const config = useRuntimeConfig();
    // Send creds to keycloak, and get a refresh token back:
    const url = `${config.public.VITE_KEYCLOAK_BASE_URL}/realms/sup/protocol/openid-connect/token`;

    const val: any = await $fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        grant_type: "password",
        username: email.value,
        password: password.value,
        client_id: "end-user",
      }),
    });

    if (!val) {
      throw new Error(`Could not log in: No response from server`);
    }
    if (val.refresh_token && val.access_token) {
      await $dwebg.login(val.refresh_token, val.access_token);
      const redirect = useRoute().query.redirect as string;
      console.log("redirect", JSON.stringify(useRoute().query));
      navigateTo(redirect ? `/${redirect}` : "/");
    } else {
      throw new Error(`Could not log in: Unexpected response from server`);
    }
  } catch (e) {
    console.log(e);
    alert(`${e}`);
  }
}
</script>
<style scoped>
.page {
  @media (max-width: 600px) {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  @media (min-width: 601px) {
    width: 1002px;
  }
}

</style>
